.grid-menu,
.grid-menu-bar {
  width: $menu-width;
  left: 0;

  @media (min-width: $bp-wider) {
    width: calc(calc(100% - 600px) / 2);
  }
}

.grid-posts,
.grid-posts-bar {
  left: 0;
  width: 100%;
  position: absolute;

  @media (min-width: $bp480) {
    left: $menu-width;
    width: calc(100% - #{$menu-width});
  }

  @media (min-width: $bp640) {
    left: 0;
    width: $posts-width;
  }

  @media (min-width: $bp830) {
    left: $menu-width;
  }

  @media (min-width: $bp-wider) {
    position: static;
    margin: 0 auto;
    width: 600px;
  }
}

.grid-details,
.grid-details-bar {
  width: 100%;

  @media (min-width: $bp480) {
    left: $menu-width;
    width: calc(100% - #{$menu-width});
  }

  @media (min-width: $bp640) {
    left: $posts-width;
    width: calc(100% - #{$posts-width});
  }

  @media (min-width: $bp830) {
    left: $menu-width + $posts-width;
    width: calc(100% - #{$menu-width} - #{$posts-width});
  }

  @media (min-width: $bp-wider) {
    left: auto;
    right: 0;
    width: calc(calc(100% - 600px) / 2);
  }
}
