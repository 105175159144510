@import 'base';

html {
  --content-height: calc(
    100% - var(--bars-height) - env(safe-area-inset-bottom)
  );

  &[data-bars-position='top'] {
    --content-height: calc(
      100% - var(--bars-height) - env(safe-area-inset-top)
    );
  }
}

.View {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  height: var(--content-height);

  top: 0;

  [data-bars-position='top'] & {
    top: var(--bars-height);
  }

  &--transitioned {
    transition: transform $trans-duration $trans-timing;
  }

  &--menu {
    z-index: 1;
    transform: translateX(-100%);
    height: calc(var(--content-height) + var(--bars-height));

    &.opened-menu {
      transform: translateX(0);
    }

    @media (min-width: $bp480) and (max-width: $bp640 - 1px) {
      transform: translateX(0);
    }

    @media (min-width: $bp830) {
      transform: translateX(0);
    }
  }

  &--posts {
    &.opened-menu {
      transform: translateX(160px);
    }
    &.opened-details {
      transform: translateX(-100%);
    }

    @media (min-width: $bp480) {
      &.opened-menu {
        transform: translateX(0);
      }
      &.opened-details {
        transform: translateX(calc(-100% - 160px));
      }
    }

    @media (min-width: $bp640) {
      &.opened-menu {
        transform: translateX(160px);
      }
      &.opened-details {
        transform: translateX(0);
      }
      &.opened-details.opened-menu {
        transform: translateX(160px);
      }
    }

    @media (min-width: $bp830) {
      &.opened-menu {
        transform: translateX(0);
      }
      &.opened-details {
        transform: translateX(0);
      }
      &.opened-details.opened-menu {
        transform: translateX(0);
      }
    }

    @media (min-width: $bp-wider) {
      border-left: 1px solid var(--border-color);

      &.opened-details {
        display: none;
      }
    }
  }

  &--details {
    z-index: 1;
    transform: translateX(100%);

    &.opened-details {
      transform: translateX(0%);

      @media (min-width: $bp-wider) {
        width: 600px;
        position: static;
        margin: 0 auto;
        border-right: 1px solid var(--border-color);
      }
    }

    @media (min-width: $bp640) {
      transform: translateX(0);
      border-left: 1px solid var(--border-color);
    }
  }
}

@keyframes highlight {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.with-key:focus {
    animation: highlight 600ms ease-in-out;
  }

  &--menu {
    @media (min-width: $bp-wider) {
      padding-inline: 2rem 4rem;
    }
  }
}
