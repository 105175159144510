.push-right {
  margin-left: auto !important;
}

.push-left {
  margin-right: auto !important;
}

.push-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.align-middle {
  vertical-align: sub !important;
}

// FLEX

.flex {
  display: flex !important;
}

.flex-y {
  align-items: center !important;
}

.flex-x {
  justify-content: center !important;
}

.flex-between {
  justify-content: space-between !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-columns {
  flex-direction: column;
}
