@import '../../styles/base';

.PostDetail {
  --corner: 3px;

  @include on-light-theme {
    --quote-bg: hsl(var(--theme-hs), 94%);
    --quote-border: hsl(var(--theme-hs), 87%);
  }

  @include on-dark-theme {
    --quote-bg: hsl(var(--theme-hs), 7%);
    --quote-border: hsl(var(--theme-hs), 14%);
  }

  &__header {
    background-color: var(--bg-color-2);
  }

  &__buttons {
    flex-shrink: 0;

    & > button {
      height: 2rem;
    }
  }

  &__self-text {
    background-color: var(--bg-color-2);
    color: var(--text-color-1);
    padding: 1px 1em;
    border-radius: 4px;
    border: 1px solid var(--border-color);
  }

  &__close-button {
    width: 12px;
  }

  &__iframe-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    & > iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__pin-icon {
    fill: var(--pinned-icon-color);
    vertical-align: middle;
    margin-right: 0.2em;
  }

  &__title {
    font-size: 1.2em;
    font-weight: 600;
  }

  &__preview {
    max-width: 100%;
    height: auto;
  }

  &__image-preview {
    cursor: zoom-in;
    border-radius: var(--corner);
    transition: opacity 150ms;

    &--gallery {
      border-radius: var(--corner) var(--corner) 0 0;
    }
  }

  &__preview-claimer {
    font-size: $scale3;
    background-color: var(--bg-color-2);
    border-radius: 0 0 var(--corner) var(--corner);
  }

  &__vote-button {
    padding: 0 3px;
  }

  &__vote-icon {
    transition: fill 200ms;
  }
}
