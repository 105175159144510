@import "../scale";

.left-border-1 {
  border-left: solid $scale1;
}

.left-border-thin {
  border-left: 1px solid;
}

.bottom-border-thin {
  border-bottom: 1px solid;
}

.top-border-thin {
  border-top: 1px solid;
}

.borders-thin {
  border: 1px solid;
}

.no-borders {
  border: 0;
}
