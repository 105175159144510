@import '../scale';

// All

.pa0 {
  padding: 0 !important;
}

.pa1 {
  padding: $scale1 !important;
}

.pa2 {
  padding: $scale2 !important;
}

.pa3 {
  padding: $scale3 !important;
}

/// Vertical

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pt1 {
  padding-top: $scale1 !important;
}

.pb1 {
  padding-bottom: $scale1 !important;
}

.pt2 {
  padding-top: $scale2 !important;
}

.pb2 {
  padding-bottom: $scale2 !important;
}

.pt3 {
  padding-top: $scale3 !important;
}

.pb3 {
  padding-bottom: $scale3 !important;
}

.pt4 {
  padding-top: $scale4 !important;
}

.pb4 {
  padding-bottom: $scale4 !important;
}

.pt5 {
  padding-top: $scale5 !important;
}

.pb5 {
  padding-bottom: $scale5 !important;
}

.pt6 {
  padding-top: $scale6 !important;
}

.pb6 {
  padding-bottom: $scale6 !important;
}

/// Horizontal

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pl1 {
  padding-left: $scale1 !important;
}

.pr1 {
  padding-right: $scale1 !important;
}

.pl2 {
  padding-left: $scale2 !important;
}

.pr2 {
  padding-right: $scale2 !important;
}

.pl3 {
  padding-left: $scale3 !important;
}

.pr3 {
  padding-right: $scale3 !important;
}

.pl4 {
  padding-left: $scale4 !important;
}

.pr4 {
  padding-right: $scale4 !important;
}

.pl5 {
  padding-left: $scale5 !important;
}

.pr5 {
  padding-right: $scale5 !important;
}

.pl6 {
  padding-left: $scale6 !important;
}

.pr6 {
  padding-right: $scale6 !important;
}
