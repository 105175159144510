@import '../../styles/base';

.PostsBar {
  display: flex;

  &__center {
    flex: 3;
  }

  &__side {
    flex: 1;
  }

  &__main-button {
    padding: 0;
    height: var(--bars-height);
    width: 56px;
    border-radius: 0;
  }

  &__text {
    text-transform: capitalize;
    font-size: 0.85rem;
    font-weight: 700;
  }

  &__menu-button {
    padding: 0 0.25rem;

    @media (min-width: $bp480) and (max-width: ($bp640 - 1px)) {
      display: none !important;
    }

    @media (min-width: $bp830) {
      display: none !important;
    }
  }

  &__subscribe-button {
    width: 24px;
    padding-left: 4px;
    padding-right: 4px;
    justify-content: center;
  }
}
