@import '../scale';

/// Vertical

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mt1 {
  margin-top: $scale1 !important;
}

.mb1 {
  margin-bottom: $scale1 !important;
}

.mt2 {
  margin-top: $scale2 !important;
}

.mb2 {
  margin-bottom: $scale2 !important;
}

.mt3 {
  margin-top: $scale3 !important;
}

.mb3 {
  margin-bottom: $scale3 !important;
}

.mt4 {
  margin-top: $scale4 !important;
}

.mb4 {
  margin-bottom: $scale4 !important;
}

.mt5 {
  margin-top: $scale5 !important;
}

.mb5 {
  margin-bottom: $scale5 !important;
}

.mt6 {
  margin-top: $scale6 !important;
}

.mb6 {
  margin-bottom: $scale6 !important;
}

/// Horizontal

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.ml1 {
  margin-left: $scale1 !important;
}

.mr1 {
  margin-right: $scale1 !important;
}

.ml2 {
  margin-left: $scale2 !important;
}

.mr2 {
  margin-right: $scale2 !important;
}

.ml3 {
  margin-left: $scale3 !important;
}

.mr3 {
  margin-right: $scale3 !important;
}

.ml4 {
  margin-left: $scale4 !important;
}

.mr4 {
  margin-right: $scale4 !important;
}

.ml5 {
  margin-left: $scale5 !important;
}

.mr5 {
  margin-right: $scale5 !important;
}

.ml6 {
  margin-left: $scale6 !important;
}

.mr6 {
  margin-right: $scale6 !important;
}

// All

.ma3 {
  margin: $scale3 !important;
}
