.ImageGallery {
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  overscroll-behavior: contain;
  touch-action: pan-x;
  padding: 1rem;
  height: 100%;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  grid-template-rows: 100%;
  place-items: center;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;

    scroll-snap-align: center;
    scroll-snap-stop: always;
  }

  &__close {
    position: fixed;
    top: 0;
    right: 0;
    padding: 1rem;
    color: white;
  }
}
