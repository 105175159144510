@mixin on-light-theme {
  @at-root html {
    @content;
  }
}

@mixin on-dark-theme {
  @media (prefers-color-scheme: dark) {
    @at-root html[data-theme='auto'] {
      @content;
    }
  }
  @at-root html[data-theme='dark'] {
    @content;
  }
}

@mixin true-black-bg {
  &[data-black-dark='y'] {
    @content;
  }
}
