.round-corners {
  border-radius: 3px !important;
}

@include on-light-theme {
  --simple-button-bg-color: hsl(var(--theme-hs), 87%);
}

@include on-dark-theme {
  --simple-button-bg-color: hsl(var(--theme-hs), 14%);
}

.simple-button {
  margin: 0;
  border: 0;
  border-radius: 4px;
  background-color: transparent;
  transition: transform 50ms, background-color 200ms;

  &:hover,
  &:focus {
    background-color: var(--simple-button-bg-color);
  }

  &:active {
    transform: translateY(2px);
  }
}

.visually-hide {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
