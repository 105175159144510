@import '../../styles/mixins';

.MenuSubheader {
  &__text {
    font-size: 0.8em;
    font-weight: 600;
    cursor: default;
  }

  &__button {
    padding: 6px 0.5rem;
    margin-right: 0.25rem;
  }
}
