.DetailsBar {
  &__title {
    border: 0;
    font-size: 0.8em;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 280px;
    cursor: default;
  }

  &__close-icon {
    width: 12px;
  }
}
