@import '../../styles/base';

.SaveButton {
  &__bookmark-icon {
    transition: fill 200ms;

    &--saved {
      fill: currentColor;
    }

    &--unsaved {
      fill: transparent;
    }
  }
}
