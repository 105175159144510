@import './base';

html {
  // Hue
  --red-h: 333;
  --purple-h: 286;
  --green-h: 156;
  --blu-h: 212;

  --neutral-h: 216;

  // Saturation
  --red-s: 99%;
  --purple-s: 99%;
  --green-s: 99%;
  --blu-s: 99%;

  --neutral-s: 29%;

  // Luminosity
  --accent-l--l: 40%;
  --accent-l--d: 50%;

  // BASE COLORS
  --theme-hs: var(--neutral-h), var(--neutral-s);

  --accent-l: var(--accent-h--l), var(--accent-s--l), var(--accent-l--l);
  --accent-d: var(--accent-h--d), var(--accent-s--d), var(--accent-l--d);
  --accent-color-d: hsl(var(--accent-d));
  --accent-color-l: hsl(var(--accent-l));

  // LIGHT THEME COLORS
  --accent-h: var(--accent-h--l);
  --accent-s: var(--accent-s--l);
  --accent-lum: var(--accent-l--l);

  --accent: var(--accent-l);
  --accent-color: hsl(var(--accent));

  --bg-color-1: hsl(var(--theme-hs), 100%);
  --bg-color-2: hsl(var(--theme-hs), 96%);

  --text-color-1: hsl(var(--theme-hs), 14%);
  --text-color-2: hsl(var(--theme-hs), 42%);

  --border-color: hsl(var(--theme-hs), 94%);

  --blue-hs: 196, 99%;

  --pinned-icon-color: hsl(var(--blue-hs), 47%);

  --active-menu-bg-color: hsla(var(--accent), 0.6);

  @include on-dark-theme {
    // DARK THEME COLORS
    --accent: var(--accent-d);
    --accent-h: var(--accent-h--d);
    --accent-s: var(--accent-s--d);
    --accent-lum: var(--accent-l--d);

    --bg-color-1: hsl(var(--theme-hs), 7%);
    --bg-color-2: hsl(var(--theme-hs), 10%);

    --text-color-1: hsl(var(--theme-hs), 87%);
    --text-color-2: hsl(var(--theme-hs), 64%);

    --border-color: hsl(var(--theme-hs), 12%);

    @include true-black-bg {
      --bg-color-1: #000;
      --bg-color-2: hsl(var(--theme-hs), 5%);
      --border-color: hsl(var(--theme-hs), 7%);
    }
  }
}
