@keyframes reveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.anim-reveal {
  animation: reveal 200ms linear forwards;
}

@keyframes reveal-down {
  from {
    opacity: 0;
    transform: translateY(-0.5rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.anim-reveal-down {
  animation: reveal-down 100ms linear forwards;
}

@keyframes pulse {
  from {
    transform: scale(0.6);
    opacity: 1;
  }

  to {
    transform: scale(1.4);
    opacity: 0;
  }
}

.anim-pulse {
  animation: pulse 0.96s ease infinite;
}

@keyframes reveal-grow {
  from {
    transform: scale(0.6);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
.anim-reveal-grow {
  animation: reveal-grow 100ms ease forwards;
}
