@import 'styles/base';

@import 'styles/reset';

html {
  //// HEIGHTS
  --bars-height: 44px;
}

@import './styles/colors';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  font-size: 15px;
  user-select: none;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  text-size-adjust: none;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, -apple-system-font,
    'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: var(--text-color-1);
  background-color: var(--bg-color-1);
  max-width: 1300px;
  margin: 0 auto;
}

button {
  font: inherit;
  color: inherit;
  background-color: inherit;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }
}

@import 'styles/grid';
@import 'styles/view';

//// UI Blocks
@import 'styles/globals/UI-Blocks/ui-root';

// COMPONENTS
@import 'components/BottomBar/BottomBar';

@import 'styles/user-content';
