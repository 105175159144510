@mixin depth-child($depth) {
  $query: '';

  @for $l from 1 through ($depth - 1) {
    $query: $query + ' ' + &;
  }

  & #{$query} {
    @content;
  }
}
