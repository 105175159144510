.ChannelCreator {
  --error-color: hsl(335, 100%, 39%);

  &__subreddit {
    display: inline-flex;
    padding: 0.5rem;
    background-color: var(--bg-color-1);
    border-radius: 3px;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    align-items: center;
  }

  &__button-remove-subreddit {
    $size: 1.5rem;
    border: 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: $size;
    height: $size;
    border-radius: 50%;
    font-size: 20px;
  }

  &__error {
    color: var(--error-color);
  }
}
