@import '../../styles/mixins';
@import '../../styles/base';

.MenuChannel {
  font-family: inherit;
  transition: color 150ms, background-color 150ms;
  border: 0;
  background-color: transparent;
  width: 100%;
  text-align: left;
  font-size: 1em;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    color: var(--text-color-1);
  }

  &:focus,
  &:active {
    background-color: var(--active-menu-bg-color);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &--selected {
    border-left: 0.25em solid var(--accent-color);
  }

  &__subreddit {
    letter-spacing: 1px;
  }

  @media (min-width: $bp-wider) {
    padding-block: 0.75rem !important;
  }
}
