@import '../../styles/base';

.Bar {
  display: flex;
  align-items: center;
  color: var(--text-color-2);
  background-color: var(--bg-color-1);
  height: var(--bars-height);

  bottom: env(safe-area-inset-bottom);
  border-top: 1px solid var(--border-color);

  [data-bars-position='top'] & {
    top: env(safe-area-inset-top);
    border-top: none;
    border-bottom: 1px solid var(--border-color);
  }

  &--menu {
    justify-content: center;
  }

  &--posts {
    justify-content: space-between;

    @media (min-width: $bp480) and (max-width: ($bp640 - 1px)) {
      justify-content: center;
    }

    @media (min-width: $bp830) {
      justify-content: center;
    }
  }

  &--details {
    padding: 0 8px 0 12px;
    justify-content: space-between;
  }
}
