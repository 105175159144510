@import '../../base';

.block {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.table {
  display: table !important;
}

.table-cell {
  display: table-cell !important;
}

.table-fixed {
  table-layout: fixed !important;
}

.hidden {
  display: none !important;
}

.hidden--s {
  @media (max-width: $bp640 - 1px) {
    display: none;
  }
}

.hidden--l {
  @media (min-width: $bp480) {
    display: none;
  }
}
