@import '../../styles/base';

.BarButton {
  display: flex;
  align-items: center;
  height: var(--bars-height);

  &:focus {
    background-color: transparent;
  }
}
