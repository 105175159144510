@import '../../styles/base';

@include on-light-theme {
  --warning-color: hsl(334, 100%, 60%);
}

@include on-dark-theme {
  --warning-color: hsl(335, 50%, 61%);
}

.Label {
  font-size: 0.8em;
  font-weight: 600;
  border-radius: 3px;

  &--warning {
    background-color: var(--warning-color) !important;
    color: white;
  }
}
