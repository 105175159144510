.Message {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  max-width: 440px;
  padding: 1rem;
  padding-right: 0;

  &__close-button {
    font-size: 1.4rem;
    top: 0; right: 0;
    padding: 0 1rem !important;
  }
}
