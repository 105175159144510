@import '../../styles/globals/scale';

.Modal {
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(1px);
    animation: reveal 300ms;
  }

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &--image {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--gallery {
      height: 100%;
      align-items: center;
    }
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
    padding: $scale2 0;
    cursor: zoom-out;

    @media (min-width: 490px) {
      padding: $scale2;
    }
  }
}
