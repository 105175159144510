@import '../../styles/mixins';
@import '../../styles/base';

.Menu {
  color: var(--text-color-2);
  padding-bottom: 32px;
  margin-bottom: 3rem;

  @media (max-width: ($bp-wider - 1px)) {
    background-color: var(--bg-color-2);
  }

  @media (min-width: $bp-wider) {
    font-size: 1.05rem;
  }
}
