@import '../../styles/base';

@keyframes button-processing {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 0;
  }
}

.MainButton {
  background-color: hsl(
    var(--accent-h),
    var(--accent-s),
    calc(var(--accent-lum) - 10%)
  );
  border-radius: 3px;
  font-size: 1em;
  font-weight: 500;

  &--processing {
    --opacity: 0.25;
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, var(--opacity)) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, var(--opacity)) 50%,
      rgba(0, 0, 0, var(--opacity)) 75%,
      transparent 75%,
      transparent
    ) !important;
    background-size: 50px;
    animation: button-processing 1000ms linear infinite;
  }
}
