@import 'mixins';

@import './globals/scale';
@import './globals/themes';

// Z-Indexes
$z-view: 1;

// Media Query Break Points
$bp480: 480px;
$bp640: 640px;
$bp830: 830px;
$bp-wide: 1160px; // 1000px + width of menu
$bp-wider: 1300px;

$menu-width: 160px;
$posts-width: 320px;

// TRANSITIONS
$trans-duration: 200ms;
$trans-timing: cubic-bezier(0.39, 0.58, 0.57, 1);
