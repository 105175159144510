.ModalForm {
  width: 360px;
  padding: 1rem;
  border-radius: 4px;
  background-color: var(--bg-color-2);

  &__input {
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    border: 0;
    padding: 0.5rem;
  }

  &__result-item {
    background-color: var(--bg-color-1);
    font-weight: 500;
    border-radius: 4px;
    border: 0;
    padding: 0.5rem 1rem;
  }
}
