@import '../../styles/base';

@mixin title-bg($focus-color) {
  background-color: $focus-color;
  box-shadow: 3px 0 0 $focus-color, -3px 0 0 $focus-color;
}

.PostItem {
  &--stickied {
    background-color: rgba(1, 176, 240, 0.1);
  }

  &--big {
    border-bottom: 0.5rem solid var(--border-color);
    padding: 1rem 0.75rem;
  }

  &__thumbnail-wrap {
    &--big {
      margin-top: 0.5rem;
      order: 2;
    }
  }

  &__warning-wrap {
    overflow: hidden;
    border-radius: 3px;
    position: relative;
  }

  &__warning-wrap &__image-wrap {
    filter: blur(24px);
  }

  &__title {
    $transition-duration: 150ms;
    color: var(--text-color-1);
    font-size: 1.06em;
    display: block;
    border-radius: 3px;
    outline: none;

    transition: background-color $transition-duration,
      box-shadow $transition-duration;

    &:focus {
      @include title-bg(hsla(var(--accent), 0.3));
    }
  }

  &--selected &__title {
    color: var(--accent-color);
    @include title-bg(hsla(var(--accent), 0.2));
  }

  &__domain {
    color: var(--accent-color);
  }

  &__image {
    width: 100%;
    border-radius: 3px;
    height: auto;
    background-color: var(--bg-color-2);

    &--big {
      height: var(--image-size);
      width: var(--image-size);
      object-fit: contain;
    }
  }

  &__data {
    font-size: 0.9em;
  }

  &__pin-icon {
    fill: var(--pinned-icon-color);
    vertical-align: middle;
    margin-right: 0.2em;
  }

  &__gallery-count {
    color: white;
    background-color: hsla(0, 0%, 0%, 0.5);
    width: fit-content;
    padding: 2px 4px;
    border-radius: 4px;
    bottom: 2px;
    right: 2px;
  }
}
