.Modal__overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: #000000b3;
  animation: .3s reveal;
  position: absolute;
  top: 0;
  left: 0;
}

.Modal__content {
  position: fixed;
  inset: 0;
}

.Modal__content--image {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Modal__content--gallery {
  height: 100%;
  align-items: center;
}

.Modal__image {
  max-width: 100%;
  max-height: 100%;
  cursor: zoom-out;
  padding: .5rem 0;
}

@media (min-width: 490px) {
  .Modal__image {
    padding: .5rem;
  }
}

.ImageGallery {
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  overscroll-behavior: contain;
  touch-action: pan-x;
  height: 100%;
  width: 100%;
  grid-template-rows: 100%;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
  place-items: center;
  gap: 1rem;
  padding: 1rem;
  display: grid;
  overflow-x: auto;
}

.ImageGallery img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.ImageGallery__close {
  color: #fff;
  padding: 1rem;
  position: fixed;
  top: 0;
  right: 0;
}

.Message {
  z-index: 9;
  max-width: 440px;
  padding: 1rem 0 1rem 1rem;
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.Message__close-button {
  font-size: 1.4rem;
  top: 0;
  right: 0;
  padding: 0 1rem !important;
}

.reset-button {
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

html {
  --bars-height: 44px;
  --red-h: 333;
  --purple-h: 286;
  --green-h: 156;
  --blu-h: 212;
  --neutral-h: 216;
  --red-s: 99%;
  --purple-s: 99%;
  --green-s: 99%;
  --blu-s: 99%;
  --neutral-s: 29%;
  --accent-l--l: 40%;
  --accent-l--d: 50%;
  --theme-hs: var(--neutral-h), var(--neutral-s);
  --accent-l: var(--accent-h--l), var(--accent-s--l), var(--accent-l--l);
  --accent-d: var(--accent-h--d), var(--accent-s--d), var(--accent-l--d);
  --accent-color-d: hsl(var(--accent-d));
  --accent-color-l: hsl(var(--accent-l));
  --accent-h: var(--accent-h--l);
  --accent-s: var(--accent-s--l);
  --accent-lum: var(--accent-l--l);
  --accent: var(--accent-l);
  --accent-color: hsl(var(--accent));
  --bg-color-1: hsl(var(--theme-hs), 100%);
  --bg-color-2: hsl(var(--theme-hs), 96%);
  --text-color-1: hsl(var(--theme-hs), 14%);
  --text-color-2: hsl(var(--theme-hs), 42%);
  --border-color: hsl(var(--theme-hs), 94%);
  --blue-hs: 196, 99%;
  --pinned-icon-color: hsl(var(--blue-hs), 47%);
  --active-menu-bg-color: hsla(var(--accent), .6);
}

@media (prefers-color-scheme: dark) {
  html[data-theme="auto"] {
    --accent: var(--accent-d);
    --accent-h: var(--accent-h--d);
    --accent-s: var(--accent-s--d);
    --accent-lum: var(--accent-l--d);
    --bg-color-1: hsl(var(--theme-hs), 7%);
    --bg-color-2: hsl(var(--theme-hs), 10%);
    --text-color-1: hsl(var(--theme-hs), 87%);
    --text-color-2: hsl(var(--theme-hs), 64%);
    --border-color: hsl(var(--theme-hs), 12%);
  }

  html[data-theme="auto"][data-black-dark="y"] {
    --bg-color-1: #000;
    --bg-color-2: hsl(var(--theme-hs), 5%);
    --border-color: hsl(var(--theme-hs), 7%);
  }
}

html[data-theme="dark"] {
  --accent: var(--accent-d);
  --accent-h: var(--accent-h--d);
  --accent-s: var(--accent-s--d);
  --accent-lum: var(--accent-l--d);
  --bg-color-1: hsl(var(--theme-hs), 7%);
  --bg-color-2: hsl(var(--theme-hs), 10%);
  --text-color-1: hsl(var(--theme-hs), 87%);
  --text-color-2: hsl(var(--theme-hs), 64%);
  --border-color: hsl(var(--theme-hs), 12%);
}

html[data-theme="dark"][data-black-dark="y"] {
  --bg-color-1: #000;
  --bg-color-2: hsl(var(--theme-hs), 5%);
  --border-color: hsl(var(--theme-hs), 7%);
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: hidden;
}

body {
  -webkit-user-select: none;
  user-select: none;
  word-wrap: break-word;
  text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
  color: var(--text-color-1);
  background-color: var(--bg-color-1);
  max-width: 1300px;
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, -apple-system-font, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 15px;
  position: relative;
}

button {
  font: inherit;
  color: inherit;
  background-color: inherit;
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed;
}

.grid-menu, .grid-menu-bar {
  width: 160px;
  left: 0;
}

@media (min-width: 1300px) {
  .grid-menu, .grid-menu-bar {
    width: calc(50% - 300px);
  }
}

.grid-posts, .grid-posts-bar {
  width: 100%;
  position: absolute;
  left: 0;
}

@media (min-width: 480px) {
  .grid-posts, .grid-posts-bar {
    width: calc(100% - 160px);
    left: 160px;
  }
}

@media (min-width: 640px) {
  .grid-posts, .grid-posts-bar {
    width: 320px;
    left: 0;
  }
}

@media (min-width: 830px) {
  .grid-posts, .grid-posts-bar {
    left: 160px;
  }
}

@media (min-width: 1300px) {
  .grid-posts, .grid-posts-bar {
    width: 600px;
    margin: 0 auto;
    position: static;
  }
}

.grid-details, .grid-details-bar {
  width: 100%;
}

@media (min-width: 480px) {
  .grid-details, .grid-details-bar {
    width: calc(100% - 160px);
    left: 160px;
  }
}

@media (min-width: 640px) {
  .grid-details, .grid-details-bar {
    width: calc(100% - 320px);
    left: 320px;
  }
}

@media (min-width: 830px) {
  .grid-details, .grid-details-bar {
    width: calc(100% - 480px);
    left: 480px;
  }
}

@media (min-width: 1300px) {
  .grid-details, .grid-details-bar {
    width: calc(50% - 300px);
    left: auto;
    right: 0;
  }
}

html {
  --content-height: calc( 100% - var(--bars-height)  - env(safe-area-inset-bottom) );
}

html[data-bars-position="top"] {
  --content-height: calc( 100% - var(--bars-height)  - env(safe-area-inset-top) );
}

.View {
  -webkit-overflow-scrolling: touch;
  height: var(--content-height);
  outline: none;
  top: 0;
  overflow-y: auto;
}

[data-bars-position="top"] .View {
  top: var(--bars-height);
}

.View--transitioned {
  transition: transform .2s cubic-bezier(.39, .58, .57, 1);
}

.View--menu {
  z-index: 1;
  height: calc(var(--content-height)  + var(--bars-height));
  transform: translateX(-100%);
}

.View--menu.opened-menu {
  transform: translateX(0);
}

@media (min-width: 480px) and (max-width: 639px) {
  .View--menu {
    transform: translateX(0);
  }
}

@media (min-width: 830px) {
  .View--menu {
    transform: translateX(0);
  }
}

.View--posts.opened-menu {
  transform: translateX(160px);
}

.View--posts.opened-details {
  transform: translateX(-100%);
}

@media (min-width: 480px) {
  .View--posts.opened-menu {
    transform: translateX(0);
  }

  .View--posts.opened-details {
    transform: translateX(calc(-100% - 160px));
  }
}

@media (min-width: 640px) {
  .View--posts.opened-menu {
    transform: translateX(160px);
  }

  .View--posts.opened-details {
    transform: translateX(0);
  }

  .View--posts.opened-details.opened-menu {
    transform: translateX(160px);
  }
}

@media (min-width: 830px) {
  .View--posts.opened-menu, .View--posts.opened-details, .View--posts.opened-details.opened-menu {
    transform: translateX(0);
  }
}

@media (min-width: 1300px) {
  .View--posts {
    border-left: 1px solid var(--border-color);
  }

  .View--posts.opened-details {
    display: none;
  }
}

.View--details {
  z-index: 1;
  transform: translateX(100%);
}

.View--details.opened-details {
  transform: translateX(0%);
}

@media (min-width: 1300px) {
  .View--details.opened-details {
    width: 600px;
    border-right: 1px solid var(--border-color);
    margin: 0 auto;
    position: static;
  }
}

@media (min-width: 640px) {
  .View--details {
    border-left: 1px solid var(--border-color);
    transform: translateX(0);
  }
}

@keyframes highlight {
  0% {
    opacity: 1;
  }

  10% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper.with-key:focus {
  animation: .6s ease-in-out highlight;
}

@media (min-width: 1300px) {
  .wrapper--menu {
    padding-inline: 2rem 4rem;
  }
}

.round-corners {
  border-radius: 3px !important;
}

html {
  --simple-button-bg-color: hsl(var(--theme-hs), 87%);
}

@media (prefers-color-scheme: dark) {
  html[data-theme="auto"] {
    --simple-button-bg-color: hsl(var(--theme-hs), 14%);
  }
}

html[data-theme="dark"] {
  --simple-button-bg-color: hsl(var(--theme-hs), 14%);
}

.simple-button {
  background-color: #0000;
  border: 0;
  border-radius: 4px;
  margin: 0;
  transition: transform 50ms, background-color .2s;
}

.simple-button:hover, .simple-button:focus {
  background-color: var(--simple-button-bg-color);
}

.simple-button:active {
  transform: translateY(2px);
}

.visually-hide {
  clip: rect(0 0 0 0);
  height: auto;
  width: 1px;
  white-space: nowrap;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.left-border-1 {
  border-left: .25rem solid;
}

.left-border-thin {
  border-left: 1px solid;
}

.bottom-border-thin {
  border-bottom: 1px solid;
}

.top-border-thin {
  border-top: 1px solid;
}

.borders-thin {
  border: 1px solid;
}

.no-borders {
  border: 0;
}

.ui-text-color-1 {
  color: var(--text-color-1);
}

.ui-text-color-2 {
  color: var(--text-color-2);
}

.ui-text-accent {
  color: var(--accent-color);
}

[style*="--theme-color-light"] {
  color: hsl(var(--theme-hs), var(--theme-color-light));
}

@media (prefers-color-scheme: dark) {
  html[data-theme="auto"] [style*="--theme-color-dark"] {
    color: hsl(var(--theme-hs), var(--theme-color-dark));
  }
}

html[data-theme="dark"] [style*="--theme-color-dark"] {
  color: hsl(var(--theme-hs), var(--theme-color-dark));
}

[style*="--theme-bg-color-light"] {
  background-color: hsl(var(--theme-hs), var(--theme-bg-color-light));
}

@media (prefers-color-scheme: dark) {
  html[data-theme="auto"] [style*="--theme-bg-color-dark"] {
    background-color: hsl(var(--theme-hs), var(--theme-bg-color-dark));
  }
}

html[data-theme="dark"] [style*="--theme-bg-color-dark"] {
  background-color: hsl(var(--theme-hs), var(--theme-bg-color-dark));
}

.bg-inherit {
  background-color: inherit !important;
}

.border-color {
  border-color: var(--border-color);
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.table {
  display: table !important;
}

.table-cell {
  display: table-cell !important;
}

.table-fixed {
  table-layout: fixed !important;
}

.hidden {
  display: none !important;
}

@media (max-width: 639px) {
  .hidden--s {
    display: none;
  }
}

@media (min-width: 480px) {
  .hidden--l {
    display: none;
  }
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mt1 {
  margin-top: .25rem !important;
}

.mb1 {
  margin-bottom: .25rem !important;
}

.mt2 {
  margin-top: .5rem !important;
}

.mb2 {
  margin-bottom: .5rem !important;
}

.mt3 {
  margin-top: .75rem !important;
}

.mb3 {
  margin-bottom: .75rem !important;
}

.mt4 {
  margin-top: 1rem !important;
}

.mb4 {
  margin-bottom: 1rem !important;
}

.mt5 {
  margin-top: 1.5rem !important;
}

.mb5 {
  margin-bottom: 1.5rem !important;
}

.mt6 {
  margin-top: 2rem !important;
}

.mb6 {
  margin-bottom: 2rem !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.ml1 {
  margin-left: .25rem !important;
}

.mr1 {
  margin-right: .25rem !important;
}

.ml2 {
  margin-left: .5rem !important;
}

.mr2 {
  margin-right: .5rem !important;
}

.ml3 {
  margin-left: .75rem !important;
}

.mr3 {
  margin-right: .75rem !important;
}

.ml4 {
  margin-left: 1rem !important;
}

.mr4 {
  margin-right: 1rem !important;
}

.ml5 {
  margin-left: 1.5rem !important;
}

.mr5 {
  margin-right: 1.5rem !important;
}

.ml6 {
  margin-left: 2rem !important;
}

.mr6 {
  margin-right: 2rem !important;
}

.ma3 {
  margin: .75rem !important;
}

.pa0 {
  padding: 0 !important;
}

.pa1 {
  padding: .25rem !important;
}

.pa2 {
  padding: .5rem !important;
}

.pa3 {
  padding: .75rem !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pt1 {
  padding-top: .25rem !important;
}

.pb1 {
  padding-bottom: .25rem !important;
}

.pt2 {
  padding-top: .5rem !important;
}

.pb2 {
  padding-bottom: .5rem !important;
}

.pt3 {
  padding-top: .75rem !important;
}

.pb3 {
  padding-bottom: .75rem !important;
}

.pt4 {
  padding-top: 1rem !important;
}

.pb4 {
  padding-bottom: 1rem !important;
}

.pt5 {
  padding-top: 1.5rem !important;
}

.pb5 {
  padding-bottom: 1.5rem !important;
}

.pt6 {
  padding-top: 2rem !important;
}

.pb6 {
  padding-bottom: 2rem !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pl1 {
  padding-left: .25rem !important;
}

.pr1 {
  padding-right: .25rem !important;
}

.pl2 {
  padding-left: .5rem !important;
}

.pr2 {
  padding-right: .5rem !important;
}

.pl3 {
  padding-left: .75rem !important;
}

.pr3 {
  padding-right: .75rem !important;
}

.pl4 {
  padding-left: 1rem !important;
}

.pr4 {
  padding-right: 1rem !important;
}

.pl5 {
  padding-left: 1.5rem !important;
}

.pr5 {
  padding-right: 1.5rem !important;
}

.pl6 {
  padding-left: 2rem !important;
}

.pr6 {
  padding-right: 2rem !important;
}

.text-1 {
  font-size: .75em;
}

.text-2 {
  font-size: 1em;
}

.text-3 {
  font-size: 1.5em;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.no-underline {
  text-decoration: none !important;
}

.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.bold-text {
  font-weight: 600 !important;
}

.thin-text {
  font-weight: 400 !important;
}

.w100 {
  width: 100% !important;
}

.w50 {
  width: 50% !important;
}

.height-100, .h100 {
  height: 100%;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-base {
  z-index: 1;
}

.push-right {
  margin-left: auto !important;
}

.push-left {
  margin-right: auto !important;
}

.push-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.align-middle {
  vertical-align: sub !important;
}

.flex {
  display: flex !important;
}

.flex-y {
  align-items: center !important;
}

.flex-x {
  justify-content: center !important;
}

.flex-between {
  justify-content: space-between !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-columns {
  flex-direction: column;
}

@keyframes reveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.anim-reveal {
  animation: .2s linear forwards reveal;
}

@keyframes reveal-down {
  from {
    opacity: 0;
    transform: translateY(-.5rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.anim-reveal-down {
  animation: .1s linear forwards reveal-down;
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(.6);
  }

  to {
    opacity: 0;
    transform: scale(1.4);
  }
}

.anim-pulse {
  animation: .96s infinite pulse;
}

@keyframes reveal-grow {
  from {
    opacity: 0;
    transform: scale(.6);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.anim-reveal-grow {
  animation: .1s forwards reveal-grow;
}

.Bar {
  color: var(--text-color-2);
  background-color: var(--bg-color-1);
  height: var(--bars-height);
  bottom: env(safe-area-inset-bottom);
  border-top: 1px solid var(--border-color);
  align-items: center;
  display: flex;
}

[data-bars-position="top"] .Bar {
  top: env(safe-area-inset-top);
  border-top: none;
  border-bottom: 1px solid var(--border-color);
}

.Bar--menu {
  justify-content: center;
}

.Bar--posts {
  justify-content: space-between;
}

@media (min-width: 480px) and (max-width: 639px) {
  .Bar--posts {
    justify-content: center;
  }
}

@media (min-width: 830px) {
  .Bar--posts {
    justify-content: center;
  }
}

.Bar--details {
  justify-content: space-between;
  padding: 0 8px 0 12px;
}

html {
  --link-text-l: 42%;
  --link-decoration-l: 87%;
}

@media (prefers-color-scheme: dark) {
  html[data-theme="auto"] {
    --link-text-l: 52%;
    --link-decoration-l: 23%;
  }
}

html[data-theme="dark"] {
  --link-text-l: 52%;
  --link-decoration-l: 23%;
}

.UserContent {
  -webkit-user-select: text;
  user-select: text;
}

@media (min-width: 1300px) {
  .UserContent p {
    line-height: 1.4;
  }
}

.UserContent a {
  color: hsl(var(--accent-h), var(--accent-s), var(--link-text-l));
  -webkit-text-decoration-color: hsl(var(--accent-h), var(--accent-s), var(--link-decoration-l));
  text-decoration-color: hsl(var(--accent-h), var(--accent-s), var(--link-decoration-l));
  text-decoration-thickness: 1.4px;
}

.UserContent a[href$=".jpg"]:before, .UserContent a[href$=".jpeg"]:before, .UserContent a[href$=".png"]:before, .UserContent a[href$=".gif"]:before, .UserContent a[href$=".gifv"]:before {
  content: url("image.e6ea9266.svg");
  vertical-align: middle;
  margin-right: 2px;
  display: inline-block;
}

.UserContent a[href*="youtube.com"]:before, .UserContent a[href*="youtu.be"]:before, .UserContent a[href*="vimeo.com"]:before {
  content: url("video.8ada2077.svg");
  vertical-align: middle;
  margin-top: 2px;
  margin-right: 2px;
  display: inline-block;
}

.UserContent blockquote {
  border-left: 6px solid var(--quote-border);
  background-color: var(--quote-bg);
  border-radius: 2px;
  margin: 1rem 0;
  padding: 1px 1rem;
}

.UserContent blockquote > p {
  margin: .5rem 0;
}

.UserContent img {
  border-radius: var(--corner);
  max-width: 100%;
  height: auto;
}

.ChannelCreator {
  --error-color: #c70053;
}

.ChannelCreator__subreddit {
  background-color: var(--bg-color-1);
  border-radius: 3px;
  align-items: center;
  margin-bottom: .5rem;
  margin-right: .5rem;
  padding: .5rem;
  display: inline-flex;
}

.ChannelCreator__button-remove-subreddit {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #0003;
  border: 0;
  border-radius: 50%;
  font-size: 20px;
}

.ChannelCreator__error {
  color: var(--error-color);
}

.ModalForm {
  width: 360px;
  background-color: var(--bg-color-2);
  border-radius: 4px;
  padding: 1rem;
}

.ModalForm__input {
  width: 100%;
  border: 0;
  border-radius: 4px;
  padding: .5rem;
  font-size: 16px;
}

.ModalForm__result-item {
  background-color: var(--bg-color-1);
  border: 0;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-weight: 500;
}

.DetailsBar__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 280px;
  cursor: default;
  border: 0;
  font-size: .8em;
  font-weight: 700;
  overflow: hidden;
}

.DetailsBar__close-icon {
  width: 12px;
}

.PostsBar {
  display: flex;
}

.PostsBar__center {
  flex: 3;
}

.PostsBar__side {
  flex: 1;
}

.PostsBar__main-button {
  height: var(--bars-height);
  width: 56px;
  border-radius: 0;
  padding: 0;
}

.PostsBar__text {
  text-transform: capitalize;
  font-size: .85rem;
  font-weight: 700;
}

.PostsBar__menu-button {
  padding: 0 .25rem;
}

@media (min-width: 480px) and (max-width: 639px) {
  .PostsBar__menu-button {
    display: none !important;
  }
}

@media (min-width: 830px) {
  .PostsBar__menu-button {
    display: none !important;
  }
}

.PostsBar__subscribe-button {
  width: 24px;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
}

.BarButton {
  height: var(--bars-height);
  align-items: center;
  display: flex;
}

.BarButton:focus {
  background-color: #0000;
}

.PostDetail {
  --corner: 3px;
}

html {
  --quote-bg: hsl(var(--theme-hs), 94%);
  --quote-border: hsl(var(--theme-hs), 87%);
}

@media (prefers-color-scheme: dark) {
  html[data-theme="auto"] {
    --quote-bg: hsl(var(--theme-hs), 7%);
    --quote-border: hsl(var(--theme-hs), 14%);
  }
}

html[data-theme="dark"] {
  --quote-bg: hsl(var(--theme-hs), 7%);
  --quote-border: hsl(var(--theme-hs), 14%);
}

.PostDetail__header {
  background-color: var(--bg-color-2);
}

.PostDetail__buttons {
  flex-shrink: 0;
}

.PostDetail__buttons > button {
  height: 2rem;
}

.PostDetail__self-text {
  background-color: var(--bg-color-2);
  color: var(--text-color-1);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 1px 1em;
}

.PostDetail__close-button {
  width: 12px;
}

.PostDetail__iframe-wrap {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.PostDetail__iframe-wrap > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.PostDetail__pin-icon {
  fill: var(--pinned-icon-color);
  vertical-align: middle;
  margin-right: .2em;
}

.PostDetail__title {
  font-size: 1.2em;
  font-weight: 600;
}

.PostDetail__preview {
  max-width: 100%;
  height: auto;
}

.PostDetail__image-preview {
  cursor: zoom-in;
  border-radius: var(--corner);
  transition: opacity .15s;
}

.PostDetail__image-preview--gallery {
  border-radius: var(--corner) var(--corner) 0 0;
}

.PostDetail__preview-claimer {
  background-color: var(--bg-color-2);
  border-radius: 0 0 var(--corner) var(--corner);
  font-size: .75rem;
}

.PostDetail__vote-button {
  padding: 0 3px;
}

.PostDetail__vote-icon {
  transition: fill .2s;
}

.SaveButton__bookmark-icon {
  transition: fill .2s;
}

.SaveButton__bookmark-icon--saved {
  fill: currentColor;
}

.SaveButton__bookmark-icon--unsaved {
  fill: #0000;
}

html {
  --reply-line-light: 70%;
  --button-gradient-start-l: 94%;
  --button-gradient-end-l: 87%;
  --replies-button-bg-image: linear-gradient( hsl(var(--theme-hs), var(--button-gradient-start-l)), hsl(var(--theme-hs), var(--button-gradient-end-l)) );
}

@media (prefers-color-scheme: dark) {
  html[data-theme="auto"] {
    --reply-line-light: 30%;
    --button-gradient-start-l: 18%;
    --button-gradient-end-l: 12%;
  }

  html[data-theme="auto"][data-black-dark="y"] {
    --button-gradient-start-l: 14%;
    --button-gradient-end-l: 7%;
  }
}

html[data-theme="dark"] {
  --reply-line-light: 30%;
  --button-gradient-start-l: 18%;
  --button-gradient-end-l: 12%;
}

html[data-theme="dark"][data-black-dark="y"] {
  --button-gradient-start-l: 14%;
  --button-gradient-end-l: 7%;
}

.PostComment {
  --line-width: 1.5px;
  box-shadow: inset var(--line-width) 0 0 var(--subway-color);
}

.PostComment--top {
  border-bottom: 2px solid var(--border-color);
  padding-block: .5rem;
}

@media (min-width: 1300px) {
  .PostComment--top {
    padding-block: .75rem;
  }
}

.PostComment .PostComment {
  --subway-color: hsl(320, 47%, var(--reply-line-light));
}

.PostComment .PostComment .PostComment {
  --subway-color: hsl(205, 41%, var(--reply-line-light));
}

.PostComment .PostComment .PostComment .PostComment {
  --subway-color: hsl(64, 42%, var(--reply-line-light));
}

.PostComment .PostComment .PostComment .PostComment .PostComment {
  --subway-color: hsl(107, 42%, var(--reply-line-light));
}

.PostComment .PostComment .PostComment .PostComment .PostComment .PostComment {
  --subway-color: hsl(217, 35%, var(--reply-line-light));
}

.PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment {
  --subway-color: hsl(7, 35%, var(--reply-line-light));
}

.PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment {
  --subway-color: hsl(205, 41%, var(--reply-line-light));
}

.PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment {
  --subway-color: hsl(320, 47%, var(--reply-line-light));
}

.PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment .PostComment {
  --subway-color: hsl(107, 42%, var(--reply-line-light));
}

.PostComment__op-label {
  background-color: var(--accent-color);
  border-radius: 3px;
  font-size: 12px;
}

.PostComment__time-ago-link.PostComment__time-ago-link {
  color: var(--text-color-3);
}

.PostComment__replies-button {
  width: 10em;
  background-image: var(--replies-button-bg-image);
  border: none;
  border-radius: 4px;
  padding: .65em 0;
  font-size: .7em;
  font-weight: 600;
  transition: transform 50ms;
  display: block;
}

.PostComment__replies-button:active {
  transform: translateY(2px);
}

.PostComment__content > p:first-child, .PostComment__content > blockquote:first-child {
  margin-top: .25rem;
}

.PostComment__content > p:last-child {
  margin-bottom: 0;
}

.VoteButtons__icon {
  stroke-width: 2px;
}



@keyframes button-processing {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 0;
  }
}

.MainButton {
  background-color: hsl(var(--accent-h), var(--accent-s), calc(var(--accent-lum)  - 10%) );
  border-radius: 3px;
  font-size: 1em;
  font-weight: 500;
}

.MainButton--processing {
  --opacity: .25;
  background-size: 50px;
  animation: 1s linear infinite button-processing;
  background-image: linear-gradient(to left, rgba(0, 0, 0, var(--opacity)) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, var(--opacity)) 50%, rgba(0, 0, 0, var(--opacity)) 75%, transparent 75%, transparent) !important;
}

.PostItem--stickied {
  background-color: #01b0f01a;
}

.PostItem--big {
  border-bottom: .5rem solid var(--border-color);
  padding: 1rem .75rem;
}

.PostItem__thumbnail-wrap--big {
  order: 2;
  margin-top: .5rem;
}

.PostItem__warning-wrap {
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.PostItem__warning-wrap .PostItem__image-wrap {
  filter: blur(24px);
}

.PostItem__title {
  color: var(--text-color-1);
  border-radius: 3px;
  outline: none;
  font-size: 1.06em;
  transition: background-color .15s, box-shadow .15s;
  display: block;
}

.PostItem__title:focus {
  background-color: hsla(var(--accent), .3);
  box-shadow: 3px 0 0 hsla(var(--accent), .3), -3px 0 0 hsla(var(--accent), .3);
}

.PostItem--selected .PostItem__title {
  color: var(--accent-color);
  background-color: hsla(var(--accent), .2);
  box-shadow: 3px 0 0 hsla(var(--accent), .2), -3px 0 0 hsla(var(--accent), .2);
}

.PostItem__domain {
  color: var(--accent-color);
}

.PostItem__image {
  width: 100%;
  height: auto;
  background-color: var(--bg-color-2);
  border-radius: 3px;
}

.PostItem__image--big {
  height: var(--image-size);
  width: var(--image-size);
  object-fit: contain;
}

.PostItem__data {
  font-size: .9em;
}

.PostItem__pin-icon {
  fill: var(--pinned-icon-color);
  vertical-align: middle;
  margin-right: .2em;
}

.PostItem__gallery-count {
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #00000080;
  border-radius: 4px;
  padding: 2px 4px;
  bottom: 2px;
  right: 2px;
}

.Icon {
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  width: var(--size);
  height: var(--size);
  display: inline-block;
}

html {
  --warning-color: #ff338b;
}

@media (prefers-color-scheme: dark) {
  html[data-theme="auto"] {
    --warning-color: #cd6a93;
  }
}

html[data-theme="dark"] {
  --warning-color: #cd6a93;
}

.Label {
  border-radius: 3px;
  font-size: .8em;
  font-weight: 600;
}

.Label--warning {
  color: #fff;
  background-color: var(--warning-color) !important;
}

.Loading {
  width: 1.6rem;
  height: 1.6rem;
  background-color: var(--accent-color);
  border-radius: 50%;
}

.Menu {
  color: var(--text-color-2);
  margin-bottom: 3rem;
  padding-bottom: 32px;
}

@media (max-width: 1299px) {
  .Menu {
    background-color: var(--bg-color-2);
  }
}

@media (min-width: 1300px) {
  .Menu {
    font-size: 1.05rem;
  }
}

.MenuSubheader__text {
  cursor: default;
  font-size: .8em;
  font-weight: 600;
}

.MenuSubheader__button {
  margin-right: .25rem;
  padding: 6px .5rem;
}

.MenuItem {
  width: 100%;
  text-align: left;
  border: 0;
  font-family: inherit;
  font-size: 1em;
  transition: color .15s, background-color .15s;
}

.MenuItem:hover, .MenuItem:focus, .MenuItem:active {
  outline: 0;
}

.MenuItem:hover {
  color: var(--text-color-1);
}

.MenuItem:focus, .MenuItem:active {
  background-color: var(--active-menu-bg-color);
}

.MenuItem::-moz-focus-inner {
  border: 0;
}

.MenuItem--selected {
  border-left: .25em solid var(--accent-color);
}

@media (min-width: 1300px) {
  .MenuItem {
    padding-block: .75rem !important;
  }
}

.MenuChannel {
  width: 100%;
  text-align: left;
  background-color: #0000;
  border: 0;
  font-family: inherit;
  font-size: 1em;
  transition: color .15s, background-color .15s;
}

.MenuChannel:hover, .MenuChannel:focus, .MenuChannel:active {
  color: var(--text-color-1);
  outline: 0;
}

.MenuChannel:focus, .MenuChannel:active {
  background-color: var(--active-menu-bg-color);
}

.MenuChannel::-moz-focus-inner {
  border: 0;
}

.MenuChannel--selected {
  border-left: .25em solid var(--accent-color);
}

.MenuChannel__subreddit {
  letter-spacing: 1px;
}

@media (min-width: 1300px) {
  .MenuChannel {
    padding-block: .75rem !important;
  }
}

/*# sourceMappingURL=index.df64e74b.css.map */
