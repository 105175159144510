@import '../styles/base';

@include on-light-theme {
  --link-text-l: 42%;
  --link-decoration-l: 87%;
}

@include on-dark-theme {
  --link-text-l: 52%;
  --link-decoration-l: 23%;
}

.UserContent {
  user-select: text;

  p {
    @media (min-width: $bp-wider) {
      line-height: 1.4;
    }
  }

  // links
  a {
    color: hsl(var(--accent-h), var(--accent-s), var(--link-text-l));
    text-decoration-color: hsl(
      var(--accent-h),
      var(--accent-s),
      var(--link-decoration-l)
    );
    text-decoration-thickness: 1.4px;
  }

  a[href$='.jpg'],
  a[href$='.jpeg'],
  a[href$='.png'],
  a[href$='.gif'],
  a[href$='.gifv'] {
    &:before {
      content: url(/img/image.svg);
      display: inline-block;
      vertical-align: middle;
      margin-right: 2px;
    }
  }

  a[href*='youtube.com'],
  a[href*='youtu.be'],
  a[href*='vimeo.com'] {
    &:before {
      content: url(/img/video.svg);
      display: inline-block;
      vertical-align: middle;
      margin-right: 2px;
      margin-top: 2px;
    }
  }

  // quotes
  blockquote {
    margin: $scale4 0;
    border-left: 6px solid var(--quote-border);
    background-color: var(--quote-bg);
    padding: 1px $scale4;
    border-radius: 2px;

    > p {
      margin: $scale2 0;
    }
  }

  img {
    border-radius: var(--corner);
    max-width: 100%;
    height: auto;
  }
}
