@import '../../styles/base';

@include on-light-theme {
  --reply-line-light: 70%;
  --button-gradient-start-l: 94%;
  --button-gradient-end-l: 87%;

  --replies-button-bg-image: linear-gradient(
    hsl(var(--theme-hs), var(--button-gradient-start-l)),
    hsl(var(--theme-hs), var(--button-gradient-end-l))
  );
}

@include on-dark-theme {
  --reply-line-light: 30%;

  --button-gradient-start-l: 18%;
  --button-gradient-end-l: 12%;

  @include true-black-bg {
    --button-gradient-start-l: 14%;
    --button-gradient-end-l: 7%;
  }
}

.PostComment {
  --line-width: 1.5px;
  box-shadow: inset var(--line-width) 0 0 var(--subway-color);

  &--top {
    border-bottom: 2px solid var(--border-color);
    padding-block: $scale2;

    @media (min-width: $bp-wider) {
      padding-block: $scale3;
    }
  }

  @include depth-child(2) {
    --subway-color: hsl(320, 47%, var(--reply-line-light));
  }
  @include depth-child(3) {
    --subway-color: hsl(205, 41%, var(--reply-line-light));
  }
  @include depth-child(4) {
    --subway-color: hsl(64, 42%, var(--reply-line-light));
  }
  @include depth-child(5) {
    --subway-color: hsl(107, 42%, var(--reply-line-light));
  }
  @include depth-child(6) {
    --subway-color: hsl(217, 35%, var(--reply-line-light));
  }
  @include depth-child(7) {
    --subway-color: hsl(7, 35%, var(--reply-line-light));
  }
  @include depth-child(8) {
    --subway-color: hsl(205, 41%, var(--reply-line-light));
  }
  @include depth-child(9) {
    --subway-color: hsl(320, 47%, var(--reply-line-light));
  }
  @include depth-child(10) {
    --subway-color: hsl(107, 42%, var(--reply-line-light));
  }

  &__op-label {
    border-radius: 3px;
    font-size: 12px;
    background-color: var(--accent-color);
  }

  &__time-ago-link {
    // Duplicate for higher specificity
    &#{&} {
      color: var(--text-color-3);
    }
  }

  &__replies-button {
    border: none;
    padding: 0.65em 0;
    display: block;
    width: 10em;
    border-radius: 4px;
    font-weight: 600;
    background-image: var(--replies-button-bg-image);
    font-size: 0.7em;
    transition: transform 50ms;

    &:active {
      transform: translateY(2px);
    }
  }

  &__content {
    > p:first-child,
    > blockquote:first-child {
      margin-top: 0.25rem;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }
}
