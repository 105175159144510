@import '../themes';

///
/// Base Colors
///

.ui-text-color-1 {
  color: var(--text-color-1);
}

.ui-text-color-2 {
  color: var(--text-color-2);
}

.ui-text-accent {
  color: var(--accent-color);
}

[style*='--theme-color-light'] {
  color: hsl(var(--theme-hs), var(--theme-color-light));
}

@include on-dark-theme {
  [style*='--theme-color-dark'] {
    color: hsl(var(--theme-hs), var(--theme-color-dark));
  }
}

[style*='--theme-bg-color-light'] {
  background-color: hsl(var(--theme-hs), var(--theme-bg-color-light));
}

@include on-dark-theme {
  [style*='--theme-bg-color-dark'] {
    background-color: hsl(var(--theme-hs), var(--theme-bg-color-dark));
  }
}

///
/// Background Colors
///

// TODO maybe get rid of this?
.bg-inherit {
  background-color: inherit !important;
}

///
/// Border Colors
///

.border-color {
  border-color: var(--border-color);
}
