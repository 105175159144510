/// Scale

.text-1 {
  font-size: 0.75em;
}

.text-2 {
  font-size: 1em;
}

.text-3 {
  font-size: 1.5em;
}

/// Transforms

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

/// Alignment

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/// Misc

.no-underline {
  text-decoration: none !important;
}

.truncate-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Weight

.bold-text {
  font-weight: 600 !important;
}

.thin-text {
  font-weight: 400 !important;
}
